html,body{
  height: 100%;
}

body{
  margin: 0 auto;
  max-width: 600px;
}

#root, .App {
  height: 100%;
  overflow-y: auto;
}
.logo {
  width: 60px;
  height: 60px;
  margin: 0px auto;
  padding: 53px 0 8px;
  display: block;
}

.form-item {
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 15px;
  background-color: #eee;
}
.form-label {
  color: #838285;
}

.form-input {
  display: flex;
}

.input-inner{
  padding-top: 10px;
  flex: 1;
  background: transparent;
  font-family: inherit;
  border: 0;
  font-size: 16px;
  outline: none;
}


.input-inner::placeholder{
  color: #515054;
}
form {
  padding: 15px;
  box-sizing: border-box;
}
.download-page {
  padding: 15px;
}
button{
  border: 0;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-family: inherit;
  height: 47px;
  padding: 4px 8px;
  border-radius: 1000px;
}
button.primary{
  background-color: #FF5207;
  margin-top: 10px;
}
button.primary[disabled]{
  background-color: #381409;
  color: #838285;
}
button[text='true']{
  background: none;
}
.primary-text{
  color: #FF5207;
  padding: 0;
  height: 32px;
  width: auto;
}

.download-img{
  width: 185px;
  height: 206px;
  display: block;
  margin: 0px auto;
}

.download-text{
  color: #838285;
  font-size: 15px;
  text-align: center;
  margin-bottom: 50px;
}

a.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 52px;
  text-decoration: none;
  margin: auto;
  border-radius: 26px;
  color: #ffffff;
}

a.ios{
  background-color: #FF5207;
  margin-bottom: 15px;
}
a.android{
  background-color: #218CF5;
}
.icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.error{
  color: #FF5207;
}
.cover{
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


.download-tip{
  padding: 15px ;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}